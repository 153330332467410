import { FC, lazy, useEffect, useState } from "react"
import { RouterProvider } from "react-router"
import { Navigate, Outlet, RouteObject, createBrowserRouter } from "react-router-dom"

import { useMeQuery } from "../graphql"
import PasswordReset from "./password-reset"

import AuthLayout from "src/components/layout/authorithation"
import DefaultLayout, { Loader } from "src/components/layout/default"
import LandingLayout from "src/components/layout/landing"
import LogOutLayout from "src/components/layout/logout"
import withSubscriptionCheck from "src/hocs/withSubscriptionCheck"

const LogIn = lazy<FC>(() => import("src/pages/log-in/index"))
const LogInMessage = lazy<FC>(() => import("src/pages/log-in/LoginMessage"))
const SignUp = lazy<FC>(() => import("src/pages/sign-up"))
const AccountSetUpPersonal = lazy<FC>(() => import("./account-set-up-corp"))
const AccountSelectCorp = lazy<FC>(() => import("./account-select-corp"))
const Settings = lazy<FC>(() => import("./settings"))
const Notifications = lazy<FC>(() => import("./notifications"))
const Companies = lazy<FC>(() => import("./companies"))
const NotFound = lazy<FC>(() => import("./not-found"))
const ExtraPage = lazy<FC>(() => import("./extra-pages"))
const LearnMore = lazy<FC>(() => import("./learnmore"))
const CompanyEditing = lazy<FC>(() => import("./company-editing"))
const Main = lazy<FC>(() => import("./main"))
const Verification = lazy<FC>(() => import("./verification"))
const VerifyEmail = lazy<FC>(() => import("./verify-email"))
const EmailConfirm = lazy<FC>(() => import("./email-confirm"))
const LogOut = lazy<FC>(() => import("./log-out"))
const Subscription = lazy<FC>(() => import("./subscription"))

const DefaultLayoutWithSubscriptionCheck = withSubscriptionCheck(DefaultLayout)

const commonRoutes: RouteObject[] = [
  {
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: "home",
        element: <Main />,
      },
      {
        path: "learn-more",
        element: <LearnMore />,
      },
      {
        path: "extra",
        element: <ExtraPage />,
      },
      {
        element: <LogOut />,
        path: "log-out",
      },
    ],
  },
  {
    element: <NotFound />,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <NotFound />,
        path: "error",
        index: true,
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ],
  },
]

const guestRoutes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: <Navigate to={"/login"} replace />,
        path: "",
      },
      {
        element: <Navigate to={"/login"} replace />,
        path: "/companies",
      },
      {
        element: <Navigate to={"/login"} replace />,
        path: "/notifications",
      },
      {
        element: <Navigate to={"/login"} replace />,
        path: "/settings",
      },
      {
        element: <Navigate to={"/login"} replace />,
        path: "/editing",
      },
      {
        element: <Navigate to={"/login"} replace />,
        path: "*",
      },
      {
        element: <SignUp />,
        path: "/sign-up",
      },
      {
        element: <LogIn />,
        path: "/login",
      },
      {
        element: <Verification />,
        path: "/verification",
      },
      {
        element: <PasswordReset />,
        path: "/password-reset",
      },
      {
        element: <VerifyEmail />,
        path: "/verify-email",
      },
      {
        element: <EmailConfirm />,
        path: "/email-confirm",
      },
    ],
  },
]

const authRoutes: RouteObject[] = [
  {
    element: <DefaultLayoutWithSubscriptionCheck />,
    children: [
      {
        path: "",
        element: <Navigate to={"/companies"} replace />,
      },
      {
        path: "login",
        element: <Navigate to={"/companies"} replace />,
      },
      {
        path: "sign-up",
        element: <Navigate to={"/companies"} replace />,
      },
      {
        element: <Companies />,
        path: "companies",
      },
      {
        element: <LogOutLayout />,
        children: [
          {
            element: <Settings />,
            path: "settings",
          },
          {
            element: <Notifications />,
            path: "notifications",
          },
        ],
      },
      {
        path: "editing/:id",
        element: <CompanyEditing />,
      },
      {
        path: "editing",
        element: <CompanyEditing />,
      },
    ],
  },
  {
    element: <LandingLayout hideProfileNav hideCookieBanner />,
    children: [
      {
        element: <Subscription />,
        loader: () => <Loader spinning />,
        path: "/subscription",
      },
    ],
  },
  {
    element: <Subscription />,
    loader: () => <Loader spinning />,
    path: "/subscription",
  },
  {
    element: <LogInMessage />,
    loader: () => <Loader spinning />,
    path: "/login/success",
  },
  {
    element: <AccountSelectCorp />,
    loader: () => <Loader spinning />,
    path: "select-type-corporation",
  },
  {
    path: "corporation-setup/:type/:filingId",
    element: <AccountSetUpPersonal />,
    loader: () => <Loader spinning />,
  },
  {
    path: "corporation-setup/:type",
    element: <AccountSetUpPersonal />,
    loader: () => <Loader spinning />,
  },
  {
    element: <Outlet />,
    path: "*",
  },
]

const Router: FC = () => {
  const { data } = useMeQuery()
  const [routes, setRoutes] = useState<RouteObject[]>(commonRoutes)

  useEffect(() => {
    if (data?.me?.id) {
      setRoutes([...authRoutes, ...commonRoutes])
    } else {
      setRoutes([...commonRoutes, ...guestRoutes])
    }
  }, [data])

  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} fallbackElement={null} future={{ v7_startTransition: true }} />
}

export default Router
