import { FC, PropsWithChildren } from "react"
import { Button, ButtonProps } from "antd"
import { ButtonType } from "antd/es/button/buttonHelpers"
import './MainButton.less'

// type that removes a field from an object
type ExcludeField<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type TypeWithoutExcludedField = ExcludeField<ButtonProps, 'type'>;

type MainButtonType = TypeWithoutExcludedField & {
  type: ButtonType | 'second' | 'white' | 'text_red',
  fontSize?: 'small' | 'default'
}

export const MainButton: FC<PropsWithChildren<MainButtonType>> = ({type, fontSize, children, ...props}) => {

  const types: {[key in MainButtonType['type']]: ButtonProps} = {
    text: { type: 'text' },
    default: { type: 'default' },
    primary: { type: 'primary' },
    dashed: { type: 'dashed' },
    ghost: { type: 'ghost' },
    link: { type: 'link' },
    second: { type: 'default', className: 'btn-second' },
    white: { type: 'primary', className: 'btn-white' },
    text_red: { type: 'text', className: 'btn-red' }
  }

  return <Button {...props} {...types[type]} style={fontSize==='small'?{fontSize:14,paddingTop:12, ...props.style}:props.style}>
    {children}
  </Button>
}