import { useFilingsQuery, useSubscriptionPlanQuery } from "../graphql"
import { useLocalStorageCompanyData } from "./useLocalStorageCompanyData"

export interface UseSubscriptionPriceResponse {
  subscriptionPlan?: SubscriptionPlan | undefined | null
  futureSubscriptionPlan?: SubscriptionPlan | undefined | null
}

export function useSubscriptionPrice(): UseSubscriptionPriceResponse {
  const { data } = useSubscriptionPlanQuery()
  const { isCompanyData, isCompanyId } = useLocalStorageCompanyData()
  const { data: allCompaniesData } = useFilingsQuery()

  const futureSubscriptionPlan = () => {
    const plan = data?.subscriptionPlan
    if (!plan?.amount || !plan?.additionalPrice) return
    const isLimitCompanies =
      (allCompaniesData?.filings?.data?.length ?? 0) < (data?.subscriptionPlan?.maxFilingCount ?? 0)
    return {
      ...plan,
      amount: plan?.amount + (isCompanyData && !isCompanyId && !isLimitCompanies ? plan?.additionalPrice : 0),
    }
  }

  return {
    subscriptionPlan: data?.subscriptionPlan,
    futureSubscriptionPlan: futureSubscriptionPlan(),
  }
}
