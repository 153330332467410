import { FC } from "react"
import "./index.less"

interface LineProps {
  margin?: string
}

const Line: FC<LineProps> = ({ margin }) => {
  const style = {
    margin: margin || "10px",
  }

  return <div className={"line"} style={style}></div>
}

export default Line
