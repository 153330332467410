import { useMediaQuery } from "react-responsive"

const sizes = { BIGDESKTOP: 1600 ,DESKTOP: 1280, TABLET: 768,  BIGMOBILE: 566} as const

function useBreakpoints(): {
  isBigDesktop: boolean
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  isBigMobile: boolean
  sizes: { readonly BIGDESKTOP: 1600; readonly DESKTOP: 1280; readonly TABLET: 768, readonly BIGMOBILE: 566}
} {
  return {
    isBigDesktop: useMediaQuery({ minWidth: sizes.BIGDESKTOP }),
    isDesktop: useMediaQuery({ minWidth: sizes.DESKTOP }),
    isTablet: useMediaQuery({ minWidth: sizes.TABLET, maxWidth: sizes.DESKTOP - 1 }),
    isBigMobile: useMediaQuery({ maxWidth: sizes.BIGMOBILE - 1 }),
    isMobile: useMediaQuery({ maxWidth: sizes.TABLET - 1 }),
    sizes,
  }
}

export { useBreakpoints }
