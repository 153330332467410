import React, { useState } from "react"
import { useNavigate } from "react-router"
import "./CookieBanner.less"

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(localStorage.getItem("cookies") !== "accepted")
  const navigate = useNavigate()

  const handleAcceptCookies = () => {
    setShowBanner(false)
    localStorage.setItem("cookies", "accepted")
  }

  return (
    <div style={{ display: showBanner ? "flex" : "none" }} className={"cookie-banner"}>
      <p>
        We use cookies to improve your experience. You can opt out of certain cookies. Find out more in our{" "}
        <span onClick={() => navigate("/extra#privacy")}>privacy policy</span>
      </p>
      <div className={"buttons-wrapper"}>
        <button onClick={handleAcceptCookies} className={"accept"}>
          Accept
        </button>
      </div>
    </div>
  )
}

export default CookieBanner
