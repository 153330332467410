import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useLayoutEffect } from "react"
import { Outlet } from "react-router-dom"

import { useLocation } from "react-router"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import Sidebar from "./Sidebar"

const Layout: FC = () => {
  const { pathname } = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const { isMobile } = useBreakpoints()

  return (
    <BaseLayout>
      {!isMobile && (
        <BaseLayout.Sider width={264} style={{ height: "100vh", width: 264 }}>
          <Sidebar />
        </BaseLayout.Sider>
      )}
      <BaseLayout style={{ backgroundColor: "#171E46" }}>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  )
}

export default Layout
