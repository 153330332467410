import { FC, SVGProps } from "react"
import { ReactComponent as ExclamCircle } from "./svgIcons/ExclamationCircleFilled.svg"
import { ReactComponent as LogOutIcon } from "./svgIcons/LogOutIcon.svg"
import { ReactComponent as RedCardIcon } from "./svgIcons/RedCardIcon.svg"
import { ReactComponent as AlertTriangle } from "./svgIcons/alert-triangle.svg"
import { ReactComponent as Arrow } from "./svgIcons/arrow.svg"
import { ReactComponent as Arrows } from "./svgIcons/arrows.svg"
import { ReactComponent as BellIcon } from "./svgIcons/bellIcon.svg"
import { ReactComponent as BlueCardIcon } from "./svgIcons/blueCardIcon.svg"
import { ReactComponent as BurgerMenuIcon } from "./svgIcons/burgerMenuIcon.svg"
import { ReactComponent as cardOrnament } from "./svgIcons/cardOrnament.svg"
import { ReactComponent as CheckCircle } from "./svgIcons/check-circle.svg"
import { ReactComponent as CheckIcon } from "./svgIcons/check-icon.svg"
import { ReactComponent as Check } from "./svgIcons/check.svg"
import { ReactComponent as CheckCircleGreen } from "./svgIcons/checkCircleGreen.svg"
import { ReactComponent as ChevronDown } from "./svgIcons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "./svgIcons/chevron-up.svg"
import { ReactComponent as ChevronLeft } from "./svgIcons/chevronLeft.svg"
import { ReactComponent as CircleAllow } from "./svgIcons/circle-allow.svg"
import { ReactComponent as CirclePlus } from "./svgIcons/circle-plus.svg"
import { ReactComponent as CircleWarn } from "./svgIcons/circle-warning.svg"
import { ReactComponent as ClockArrow } from "./svgIcons/clock-arrow.svg"
import { ReactComponent as Close } from "./svgIcons/close.svg"
import { ReactComponent as CloseCircleFilled } from "./svgIcons/closeCircleFilled.svg"
import { ReactComponent as CloseIcon } from "./svgIcons/closeIcon.svg"
import { ReactComponent as CloseRed } from "./svgIcons/closeRed.svg"
import { ReactComponent as CompaniesIcon } from "./svgIcons/companiesIcon.svg"
import { ReactComponent as Disable } from "./svgIcons/disable.svg"
import { ReactComponent as DisconnectIcon } from "./svgIcons/disconectIcon.svg"
import { ReactComponent as DoneIcon } from "./svgIcons/doneIcon.svg"
import { ReactComponent as Download } from "./svgIcons/download.svg"
import { ReactComponent as Edit } from "./svgIcons/edit.svg"
import { ReactComponent as EditWhite } from "./svgIcons/editwhite.svg"
import { ReactComponent as EmptyCompanies } from "./svgIcons/empty-companies.svg"
import { ReactComponent as Files } from "./svgIcons/files.svg"
import { ReactComponent as Fish } from "./svgIcons/fish.svg"
import { ReactComponent as GreenCheck } from "./svgIcons/green-check.svg"
import { ReactComponent as House } from "./svgIcons/house.svg"
import { ReactComponent as IconList } from "./svgIcons/icon-list.svg"
import { ReactComponent as Line } from "./svgIcons/line.svg"
import { ReactComponent as Loop } from "./svgIcons/loop.svg"
import { ReactComponent as MailIcon } from "./svgIcons/mailIcon.svg"
import { ReactComponent as masterCard } from "./svgIcons/masterCard.svg"
import { ReactComponent as MenuBurger } from "./svgIcons/menu-burger.svg"
import { ReactComponent as ModalOval } from "./svgIcons/modal-oval.svg"
import { ReactComponent as More } from "./svgIcons/more.svg"
import { ReactComponent as NoItems } from "./svgIcons/no-items.svg"
import { ReactComponent as Notification } from "./svgIcons/notification.svg"
import { ReactComponent as Payment } from "./svgIcons/payment.svg"
import { ReactComponent as Plus } from "./svgIcons/plus.svg"
import { ReactComponent as PrivacyIcon } from "./svgIcons/privacyIcon.svg"
import { ReactComponent as ProcentIcon } from "./svgIcons/procent-icon.svg"
import { ReactComponent as QuestionCircle } from "./svgIcons/question-circle.svg"
import { ReactComponent as QuestionIcon } from "./svgIcons/questionIcon.svg"
import { ReactComponent as SettingIcons } from "./svgIcons/settingsIcon.svg"
import { ReactComponent as SmallCard } from "./svgIcons/small-card.svg"
import { ReactComponent as sortCheck } from "./svgIcons/sort-check.svg"
import { ReactComponent as BlurSpot } from "./svgIcons/spot-white.svg"
import { ReactComponent as SwapRight } from "./svgIcons/swap-right.svg"
import { ReactComponent as Trash } from "./svgIcons/trash.svg"
import { ReactComponent as WarningSpot } from "./svgIcons/warning.svg"
import { ReactComponent as AffordablePricing } from "./svgImg/affordable-pricing.svg"
import { ReactComponent as HeroImg } from "./svgImg/heroImg.svg"
import { ReactComponent as HowItWorks1 } from "./svgImg/how-it-works-1.svg"
import { ReactComponent as HowItWorks2 } from "./svgImg/how-it-works-2.svg"
import { ReactComponent as HowItWorks3 } from "./svgImg/how-it-works-3.svg"
import { ReactComponent as LogoImg } from "./svgImg/logo.svg"
import { ReactComponent as OfficeImg } from "./svgImg/officeImg.svg"

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  howItWorks1: HowItWorks1,
  howItWorks2: HowItWorks2,
  howItWorks3: HowItWorks3,
  affordablePricing: AffordablePricing,
  logoImg: LogoImg,
  checkCircle: CheckCircle,
  heroImg: HeroImg,
  officeImg: OfficeImg,
  companiesIcon: CompaniesIcon,
  bellIcon: BellIcon,
  settingIcons: SettingIcons,
  questionIcon: QuestionIcon,
  privacyIcon: PrivacyIcon,
  logOutIcon: LogOutIcon,
  blueCardIcon: BlueCardIcon,
  redCardIcon: RedCardIcon,
  disconnectIcon: DisconnectIcon,
  doneIcon: DoneIcon,
  mailIcon: MailIcon,
  checkCircleGreen: CheckCircleGreen,
  arrows: Arrows,
  edit: Edit,
  editWhite: EditWhite,
  files: Files,
  house: House,
  loop: Loop,
  plus: Plus,
  trash: Trash,
  line: Line,
  cardOrnament: cardOrnament,
  check: Check,
  masterCard: masterCard,
  sortCheck: sortCheck,
  close: Close,
  closeCircleFilled: CloseCircleFilled,
  burgerMenuIcon: BurgerMenuIcon,
  closeIcon: CloseIcon,
  questionCircle: QuestionCircle,
  iconList: IconList,
  download: Download,
  greenCheck: GreenCheck,
  modalOval: ModalOval,
  procentIcon: ProcentIcon,
  smallCard: SmallCard,
  noItems: NoItems,
  emptyCompanies: EmptyCompanies,
  payment: Payment,
  swapRight: SwapRight,
  fish: Fish,
  menuBurger: MenuBurger,
  notification: Notification,
  chevronLeft: ChevronLeft,
  more: More,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  checkIcon: CheckIcon,
  exclamCircle: ExclamCircle,
  closeRed: CloseRed,
  blurSpot: BlurSpot,
  warningIcon: WarningSpot,
  clockArrow: ClockArrow,
  circleWarn: CircleWarn,
  circleAllow: CircleAllow,
  circlePlus: CirclePlus,
  disable: Disable,
  alertTriangle: AlertTriangle,
  arrow: Arrow,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: string }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}
