import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, memo, ReactNode, useContext, useEffect, useState, type FC, type PropsWithChildren } from "react"
import { version } from "../../../package.json"
import {
  default as introspection,
  useCheckDeletedAccountLazyQuery,
  useDefaultPaymentMethodQuery,
  useLoginMutation,
  useMeQuery,
  useRegisterMutation,
  useSubscriptionPlanQuery,
} from "../../graphql"
import { useSubscriptionPrice, UseSubscriptionPriceResponse } from "../../hooks/useSubscriptionPrice"
import Router from "../../pages"
import theme from "../../themes"

type ContextProps = {
  app: { version: string }
}

const app: ContextProps["app"] = { version }

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
    headers: localStorage.getItem("token") ? { Authorization: `Bearer ${localStorage.getItem("token")}` } : {},
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

export interface AuthContextType {
  user: string | null | undefined
  logout: () => void
  login: ({ email, password }: { email: string; password: string }) => Promise<void>
  loginError: string
  registerError: string
  register: ({ email, password, username }: { email: string; password: string; username: string }) => Promise<void>
  defaultPaymentMethod: string | undefined | null
  subscription: UseSubscriptionPriceResponse | undefined | null
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

// Create a custom hooks to use the AuthContext
export function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}

// Create the AuthProvider component
interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [loginMutation] = useLoginMutation()
  const [registerMutation] = useRegisterMutation()
  const { data: me, error, loading } = useMeQuery()
  const { data: defaultPaymentMethodData } = useDefaultPaymentMethodQuery()
  const subscription = useSubscriptionPrice()

  console.log(subscription)

  useEffect(() => {
    let isUnauthorized
    if (error?.networkError && error.networkError instanceof Object && "response" in error.networkError) {
      isUnauthorized = error?.networkError?.response?.status === 401
    }
    if (isUnauthorized && !loading) {
      localStorage.removeItem("token")
      location.reload()
    }
  }, [error, loading])

  const [loginError, setLoginError] = useState("")
  const [registerError, setRegisterError] = useState("")

  const login = async ({ email, password }: { email: string; password: string }) => {
    await loginMutation({
      variables: {
        input: {
          identifier: email,
          password: password,
        },
      },
    })
      .then(result => {
        if (result.data?.login.user.deleted) {
          throw new Error("User is deleted!")
        }

        result?.data?.login?.jwt && localStorage.setItem("token", result?.data?.login?.jwt as string)
        setLoginError("")
        const searchParams = new URLSearchParams(location.search)
        const verified = searchParams.get("verified")
        verified ? window.location.replace("/select-type-corporation") : window.location.replace("/companies")
      })
      .catch(error => setLoginError(error.message))
  }

  const register = async ({ email, password }: { email: string; password: string; username: string }) => {
    await registerMutation({
      variables: {
        input: { email: email, password: password, username: email },
      },
    })
      .then(result => {
        setRegisterError("")
        // result?.data?.register?.jwt && localStorage.setItem("token", result?.data?.register?.jwt as string)
        result?.data?.register?.user && localStorage.setItem("email", result?.data?.register?.user?.email as string)
        window.location.replace("/verification")
      })
      .catch(error => setRegisterError(error.message))
  }

  const logout = () => {
    localStorage.removeItem("token")
    location.reload()
  }

  return (
    <AuthContext.Provider
      value={{
        user: me?.me?.id ?? undefined,
        logout,
        login,
        loginError,
        register,
        registerError,
        defaultPaymentMethod: defaultPaymentMethodData?.defaultPaymentMethod?.paymentMethodId,
        subscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider app={app}>
      <AuthProvider>
        <ConfigProvider theme={theme}>
          <Router />
        </ConfigProvider>
      </AuthProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp }

export default App
