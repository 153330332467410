import React from "react"
import { Button, Col, Form, Input, notification, Row, Space, Typography } from "antd"
import { Link, Navigate, useLocation } from "react-router-dom"
import Line from "../../shared/ui/Line"
import "./../../components/layout/authorithation/index.less"
import { useCheckDeletedAccountLazyQuery, useResetPasswordMutation } from "../../graphql"
import { notificationMessage, notificationStyle } from "../../themes/notification"

const PasswordReset = () => {
  const locationn = useLocation()
  const searchParams = new URLSearchParams(locationn.search)
  const code = searchParams.get('code')
  const [api, contextHolder] = notification.useNotification(notificationStyle)

  if(!code) return <Navigate to={'/login'} replace />

  const [resetPassword] = useResetPasswordMutation()

  const onFinish = async (values: MutationResetPasswordArgs) => {
    code && resetPassword({variables:{code, password: values.password, passwordConfirmation: values.passwordConfirmation}})
      .then(res => {
        if(res?.data?.resetPassword?.jwt){
          localStorage.setItem('token', res?.data?.resetPassword?.jwt)
          location.replace('/companies')
        }
      })
      .catch(err => api.error(notificationMessage({message:err.message,placement:'top',type:'error'})))
  }

  return (
    <React.Fragment>
      {contextHolder}
      <Col style={{marginTop:100}}>
        <Typography style={{marginTop: "18px" }} className={"auth-title"}>Password reset</Typography>
        <Form
          colon={false}
          requiredMark={false}
          layout={"vertical"}
          className={"auth-form"}
          onFinish={onFinish}
        >
          <React.Fragment>
            <Space size={18} direction={'vertical'} style={{width:'100%'}}>
              <Row>
                <Form.Item name={"password"} label={'New password'} rules={[
                  { required: true, message: 'Please enter your password' },
                  { min: 8, message: 'Password must be at least 8 characters' }
                ]}>
                  <Input.Password size={"large"} placeholder={"Enter password"} />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  name={"passwordConfirmation"}
                  label={'Confirm password'}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject('Make sure to enter the same password')
                      },
                    }),
                  ]}
                >
                  <Input.Password size={"large"} placeholder={"Enter password"} />
                </Form.Item>
              </Row>
            </Space>
            <Row style={{ marginTop: 48 }}>
              <Col span={24}>
                <Form.Item>
                  <Button type={"primary"} size={"large"} htmlType={"submit"} block>
                    Set password
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        </Form>

        <Line margin={"48px 0"} />
        <Row>
          <p className={"text-sign-up"}>Don't have an account?</p>
          <Link to={"/sign-up"} className={"link-sign-up"}>
            Sign up
          </Link>
        </Row>
      </Col>
    </React.Fragment>
  )
}

export default PasswordReset
