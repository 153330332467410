import LandingContainer from "../../../shared/ui/LandingContainer"
import CookieBanner from "./CookieBanner"
import Footer from "./Footer"
import Header, { HeaderType } from "./Header"
import React, { FC, Suspense, useLayoutEffect } from "react"
import { Outlet } from "react-router-dom"
import { useLocation } from "react-router"

type LandingType = { hideCookieBanner?: boolean }

const Landing: FC<LandingType & HeaderType> = ({hideProfileNav, hideCookieBanner}) => {
  const { pathname } = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <LandingContainer>
      <Header hideProfileNav={hideProfileNav} />
      <Suspense>
        <Outlet />
      </Suspense>
      <Footer />
      {!hideCookieBanner && <CookieBanner />}
    </LandingContainer>
  )
}
export default Landing
