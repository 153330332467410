import { Col, Row } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"

import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { SvgIcon } from "../../svg"
import "./index.less"

interface LogoProps {
  showText?: boolean
}

const Logo: FC<LogoProps> = ({ showText = true }) => {
  const { isDesktop } = useBreakpoints()
  return (
    <Link to={"/home"} className={"landing-logo"}>
      <Row>
        <Col>
          <SvgIcon type={"logoImg"} />
        </Col>
        <Col>{showText && isDesktop && <p className={"logo-text"}>Franny</p>}</Col>
      </Row>
    </Link>
  )
}

export default Logo
