import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Typography: {
      colorText: "white",
      fontSize: 14,
      fontFamily: "'Montserrat', sans-serif",
      fontFamilyCode: "'Montserrat', sans-serif",
    },
    Drawer: {
      footerPaddingBlock: 0,
      colorBgElevated: "rgb(23, 30, 70)",
      fontSizeLG: 18,
      lineHeightLG: 1.4,
      lineWidth: 0,
      footerPaddingInline: 0,
      padding: 0,
      paddingLG: 0,
      paddingXS: 0,
    },
    Modal: {
      margin: 0,
      marginSM: 0,
      marginXS: 0,
      marginLG: 0,
      padding: 0,
      paddingContentHorizontalLG: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
      contentBg: "rgb(23, 30, 70)",
      footerBg: "rgb(23, 30, 70)",
      headerBg: "rgb(23, 30, 70)",
    },
    Tabs: {
      lineType: "none",
      horizontalMargin: "0 0 60px 0",
    },
    DatePicker: {
      colorBgContainer: "rgb(12, 17, 50)",
      colorFillAlter: "rgba(193, 24, 24, 0.02)",
      colorBorder: "rgb(137, 43, 43)",
      colorBgElevated: "rgb(12, 17, 50)",
      colorText: "rgba(255, 255, 255, 0.88)",
      colorTextDescription: "rgba(255, 255, 255, 0.45)",
      colorTextDisabled: "rgba(160, 131, 131, 0.25)",
      colorTextHeading: "rgba(255, 255, 255, 0.88)",
      colorTextLightSolid: "rgb(255, 255, 255)",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.4)",
      colorLinkActive: "rgb(85, 88, 93)",
      controlItemBgActive: "rgb(46, 98, 139)",
      colorTextQuaternary: "rgba(158, 24, 24, 0.25)",
      colorSplit: "rgba(153, 26, 26, 0.06)",
      colorLinkHover: "rgb(9, 69, 134)",
      colorPrimary: "rgb(19, 74, 152)",
    },
    Dropdown: {
      zIndexPopup: 10000000000000,
    },
    Button: {
      lineHeight: 1,
      controlHeight: 40,
      fontSize: 18,
      colorPrimary: "#FCA311",
      colorBorder: "white",
      colorBgTextHover: "transparent",
      colorBgTextActive: "transparent",
      colorPrimaryHover: "transparent",
    },
    Switch: {
      colorPrimary: "#FCA311",
      colorPrimaryHover: "#FCA845",
    },
    Input: {
      colorBorder: "rgba(255, 255, 255, 0.1)",
      colorText: "white",
    },
    InputNumber: {
      colorBgContainer: "transparent",
      colorBorder: "rgba(255, 255, 255, 0.1)",
      colorPrimaryHover: "#FCA311",
      colorText: "white",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.4)",
      fontSizeLG: 14,
    },
    Select: {
      colorBgElevated: "#0C1132",
      colorText: "white",
      colorPrimaryHover: "#FCA311",
      colorTextPlaceholder: "rgba(255, 255, 255, 0.4)",
    },
    Spin: {
      colorPrimary: "#FCA311",
    },
    Notification: {
      colorBgElevated: "#0C1132",
      colorText: "white",
      colorTextHeading: "white",
    },
    Checkbox: {
      colorBgContainer: "transparent",
    },
    Collapse: {
      colorText: "rgba(255, 255, 255, 0.70)",
      colorBorder: "rgba(255,255,255,.1)",
    },
    Alert: {
      colorWarningBg: "rgba(252, 163, 17, 0.05)",
      colorWarningBorder: "rgba(252, 163, 17, 0.40)",
      colorTextHeading: "white",
      colorText: "rgba(255, 255, 255, 0.70)",
      fontSizeLG: 18,
    },
    Divider: {
      colorSplit: "rgba(255, 255, 255, 0.10)",
    },
  },
}

export default theme
