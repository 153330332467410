import { Col } from "antd"
import { FC } from "react"
import { useNavigate } from "react-router"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { SvgIcon } from "../../shared/svg"
import { useAuth } from "../app"
import { MainButton } from "./buttons/MainButton"

export const LogOutButton: FC = () => {
  const { isMobile } = useBreakpoints()
  const { logout } = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    navigate("/login")
    logout()
  }

  return (
    <Col>
      <MainButton block={!isMobile} type={"ghost"} icon={<SvgIcon type={"logOutIcon"} />} onClick={handleLogout}>
        {!isMobile && "Sign out"}
      </MainButton>
    </Col>
  )
}
