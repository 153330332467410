import { notification } from "antd"
import { useNavigate } from "react-router"
import { useVerify } from "../pages/companies/hooks/useVerify"
import { notificationStyle } from "../themes/notification"
import { useFilingMutations } from "./useFilingMutations"

interface UseLocalStorageCompanyDataResponse {
  createLocalStorage: (args: { id?: string; data?: Filing; redirectUrl?: string }) => void
  saveCompanyDataFromLocalStorage: () => Promise<void>
  isCompanyData?: boolean
  isNewCompany?: boolean
  isCompanyId?: boolean
  clearLocalStorage: () => void
  companyData?: Filing | undefined
  toRedirectUrl?: () => void
}

export function useLocalStorageCompanyData(): UseLocalStorageCompanyDataResponse {
  const [api, contextHolder] = notification.useNotification(notificationStyle)

  const { updateFiling, createFiling } = useFilingMutations()
  const { verify } = useVerify({ api })
  const createLocalStorage: UseLocalStorageCompanyDataResponse["createLocalStorage"] = ({ id, data, redirectUrl }) => {
    if (id) localStorage.setItem("verifyCompanyId", String(id))
    if (data) localStorage.setItem("companyData", JSON.stringify(data))
    if (redirectUrl) localStorage.setItem("redirectUrl", redirectUrl)
  }

  const saveCompanyData = async () => {
    const filingId = localStorage.getItem("verifyCompanyId")
    const filingData = localStorage.getItem("companyData")
    const redirectUrl = localStorage.getItem("redirectUrl")

    if (filingId && filingData) {
      await updateFiling(filingId, JSON.parse(filingData)).then(res => verifyCompany(res?.data?.data?.id))
      clearLocalStorage()
      return
    }

    if (filingData) {
      await createFiling(JSON.parse(filingData)).then(res => verifyCompany(res?.data?.id))
      clearLocalStorage()
      return
    }

    if (filingId) {
      await verifyCompany(filingId)
      return
    }

    if (redirectUrl) {
      window.location.replace(redirectUrl)
      return
    }
  }

  const verifyCompany = async (id: string) => {
    await verify(id)
    clearLocalStorage()
  }

  const clearLocalStorage = () => {
    localStorage.removeItem("verifyCompanyId")
    localStorage.removeItem("companyData")
  }

  const getCompanyData = () => {
    const LSData = localStorage.getItem("companyData")
    if (LSData) {
      return JSON.parse(LSData)
    }
    return undefined
  }

  const toHref = () => {
    const redirectUrl = localStorage.getItem("redirectUrl")
    localStorage.removeItem("redirectUrl")
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      window.location.replace("/companies")
    }
  }

  return {
    createLocalStorage,
    saveCompanyDataFromLocalStorage: saveCompanyData,
    isCompanyId: !!localStorage.getItem("verifyCompanyId"),
    isCompanyData: !!localStorage.getItem("companyData"),
    isNewCompany: !!localStorage.getItem("companyData") && !localStorage.getItem("verifyCompanyId"),
    clearLocalStorage,
    companyData: getCompanyData(),
    toRedirectUrl: toHref,
  }
}
