import { Spin, SpinProps } from "antd"
import { FC } from "react"

import "./Loader.less"

type LoaderProps = {
  fullScreen?: boolean
}

const Loader: FC<LoaderProps & SpinProps> = ({ fullScreen = false, ...props }) =>
  fullScreen ? (
    <div className={"full-screen-loader"}>
      <Spin size={"large"} {...props} />
    </div>
  ) : (
    <Spin size={"small"} {...props} />
  )

export { Loader }
