import { Link } from "react-router-dom"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import "./Footer.less"
const Footer = () => {
  const { isMobile } = useBreakpoints()

  return (
    <footer className={"footer-landing"}>
      {!isMobile && <div className={"footer-line"}></div>}
      <div className={"footer-landing-container"}>
        <p className={"footer-landing-copyright"}>© 2024 Franny. All rights reserved.</p>
        {isMobile && <div className={"footer-line"}></div>}
        <div className={"footer-landing-links"}>
          <Link to={"/extra#privacy"} className={"footer-landing-link"}>
            Privacy Policy
          </Link>
          <Link to={"/extra#terms"} className={"footer-landing-link"}>
            Terms of Service
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
