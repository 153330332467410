import moment from "moment"
import React, { ComponentType, useEffect } from "react"
import { useNavigate } from "react-router"
import { Loader } from "src/components/layout/default"
import { useMeQuery, useSubscriptionPlanQuery } from "src/graphql"

type WithSubscriptionCheckReturnType = <P extends object>(WrappedComponent: ComponentType<P>) => React.FC<P>

const withSubscriptionCheck: WithSubscriptionCheckReturnType = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const WithSubscriptionCheck: React.FC<P> = props => {
    const navigate = useNavigate()

    const { data, loading } = useSubscriptionPlanQuery()
    const { data: meData, loading: meLoading } = useMeQuery()
    useEffect(() => {
      // Check if trialActivationDate exists and is within the last 7 days
      if (!meLoading && meData?.me?.trialActivationDate) {
        const trialActivationDate = moment(meData.me.trialActivationDate)
        const now = moment()
        const expired = now.diff(trialActivationDate, "days") >= 7

        if (expired) {
          if (!loading && !data?.subscriptionPlan?.date) {
            navigate("/subscription")
          }
        }
      }
    }, [data?.subscriptionPlan?.date, loading, meData?.me?.trialActivationDate, meLoading, navigate])

    if (loading) {
      return <Loader fullScreen spinning />
    }
    return <WrappedComponent {...props} />
  }

  return WithSubscriptionCheck
}

export default withSubscriptionCheck
