import { Col, Modal, Row } from "antd"
import React, { Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction } from "react"
import { SvgIcon } from "../../../shared/svg"
import { MainButton } from "../buttons/MainButton"
import "./ModalComponent.less"

type ModalIconType = "error" | "warn" | "question" | "done" | "plus"

export type ModalVisibleType = {
  visible: boolean
  setVisibility: Dispatch<SetStateAction<boolean>>
  blockOnCancel?: boolean
  afterClose?: () => void
}

export type ModalType = {
  title: string
  subtitle: ReactNode
  icon: ModalIconType
  leftBtn?: { onClick: () => void; label: string }
  rightBtn?: { onClick: () => void; label: string }
  loading?: boolean
  afterClose?: () => void
  titlePadding?: number
} & ModalVisibleType

export const ModalComponent: FC<PropsWithChildren<ModalType>> = ({
  visible,
  setVisibility,
  blockOnCancel,
  title,
  subtitle,
  leftBtn,
  loading,
  rightBtn,
  afterClose,
  icon,
  titlePadding
}) => {
  const mainIcon: { [key in ModalIconType]: string } = {
    done: "checkIcon",
    error: "closeCircleFilled",
    question: "questionCircle",
    warn: "warningIcon",
    plus: "circlePlus",
  }

  return (
    <ModalContainer
      afterClose={afterClose ? afterClose : undefined}
      visible={visible}
      setVisibility={setVisibility}
      blockOnCancel={blockOnCancel}
    >
      <div className={"modal"}>
        <div className={"modal-header"}>
          <SvgIcon onClick={() => setVisibility(false)} type={"close"} />
        </div>
        <div className={"modal-body"}>
          <div className={"body-header"}>
            <SvgIcon type={mainIcon[icon]} />
          </div>
          <div className={"body-text"}>
            <h2 style={{padding: `0 ${titlePadding}px`}}>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </div>
        <Row justify={"center"} style={{ marginTop: 48 }}>
          <Col xs={24} md={17}>
            <Row gutter={[16, 16]} justify={"space-between"}>
              {leftBtn && (
                <Col xs={{ span: 24, order: 2 }} md={{ span: rightBtn ? 12 : 24, order: 1 }}>
                  <MainButton block type={"text"} onClick={leftBtn?.onClick} loading={loading}>
                    {leftBtn?.label}
                  </MainButton>
                </Col>
              )}
              {rightBtn && (
                <Col xs={{ span: 24, order: 1 }} md={{ span: leftBtn ? 12 : 24, order: 2 }}>
                  <MainButton block type={"primary"} onClick={rightBtn?.onClick} loading={loading}>
                    {rightBtn?.label}
                  </MainButton>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </ModalContainer>
  )
}

type ModalCustomType = {
  title: string
  btn?: { label: string; onClick: () => void }
} & ModalVisibleType

export const ModalCustomComponent: FC<PropsWithChildren<ModalCustomType>> = ({
  visible,
  setVisibility,
  blockOnCancel,
  children,
  title,
  afterClose,
  btn,
}) => {
  return (
    <ModalContainer
      afterClose={afterClose ? afterClose : undefined}
      visible={visible}
      setVisibility={setVisibility}
      blockOnCancel={blockOnCancel}
    >
      <div className={"modal-annual"}>
        <div className={"modal-header"}>
          <h2>{title}</h2>
          {!blockOnCancel && <SvgIcon onClick={() => setVisibility(false)} type={"close"} />}
        </div>
        <div className={"modal-body"}>{children}</div>
      </div>
    </ModalContainer>
  )
}

const ModalContainer: FC<PropsWithChildren<ModalVisibleType>> = ({
  visible,
  setVisibility,
  children,
  blockOnCancel = false,
  afterClose,
}) => {
  const space = 16
  return (
    <Modal
      afterClose={afterClose ? afterClose : undefined}
      onCancel={blockOnCancel ? undefined : () => setVisibility(false)}
      closeIcon={" "}
      width={600 + space * 2}
      open={visible}
      footer={false}
      style={{ padding: `0 ${space}px` }}
      centered
    >
      {children}
    </Modal>
  )
}
