import { SvgIcon } from "../shared/svg"
import React from "react"
import { ArgsProps } from "antd/es/notification/interface"

export const notificationStyle = { bottom: 96 }
type NotificationType = 'success'|'error'

export const notificationMessage = (config:{message:string, placement?:'bottom'|'top', type?:NotificationType}): ArgsProps => {
  const typeIcon: {[key in NotificationType]: string} = {
    success: 'checkCircleGreen',
    error: 'closeCircleFilled'
  }

  return {
    message: config?.message,
    duration: 3,
    placement: config?.placement ?? 'bottom',
    icon: <SvgIcon type={typeIcon[config?.type ?? 'success']} />,
    closeIcon: false
  }
}