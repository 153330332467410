import { Button, Row } from "antd"
import { UserOutlined } from '@ant-design/icons';
import { FC, Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { useBreakpoints } from "../../../hooks/useBreakpoints"
import { SvgIcon } from "../../../shared/svg"
import Logo from "../../../shared/ui/Logo"

import BurgerMenu from "./components/headerComponents/BurgerMenu"
import "./Header.less"
import { useMeQuery } from "../../../graphql"
import { useAuth } from "../../app"
import { MainButton } from "../../elements/buttons/MainButton"

export type HeaderType = {hideProfileNav?: boolean}

const Header: FC<HeaderType> = ({hideProfileNav}) => {

  const {user, logout} = useAuth()

  const [showBurgerMenu, setShowBurgerMenu] = useState(false)

  const { isDesktop, isMobile } = useBreakpoints()

  const openBurgerMenu = () => {
    setShowBurgerMenu(!showBurgerMenu)
    document.body.style.overflow = showBurgerMenu ? "auto" : "hidden"
  }

  return (
    <header className={"head"}>
      <Row align={"middle"}>
        {isMobile && (
          <SvgIcon
            style={{ cursor: "pointer", zIndex: 10, marginRight: 16 }}
            onClick={openBurgerMenu}
            type={showBurgerMenu ? "closeIcon" : "burgerMenuIcon"}
          />
        )}
        {isMobile && <BurgerMenu isOpen={showBurgerMenu} onClose={openBurgerMenu} />}
        <Logo />
      </Row>
      <div className={"buttons"}>
        {!isMobile && (
          <Link to={"/learn-more"} style={{ marginRight: 36 }}>
            <MainButton type={'text'}>Learn more</MainButton>
          </Link>
        )}
        {!isMobile && !user && (
          <Link to={"/login"} style={{ marginRight: 16 }}>
            <Button size={"large"} className={"ant-btn-white-border"}>
              Log In
            </Button>
          </Link>
        )}
        {user
          ? <Fragment>
            <Link to={"/log-out"} style={{ marginRight: 16 }}>
              <Button size={"large"} className={"ant-btn-white-border"}>
                Sign out
              </Button>
            </Link>
            {!hideProfileNav &&
              <Link to={"/companies"} style={{ marginRight: 16 }}>
                <MainButton icon={<UserOutlined />} type={'primary'}/>
              </Link>
            }
          </Fragment>
          : <Link to={"/sign-up"} style={{ textDecoration: "none" }}>
            <Button size={"large"} className={"sign-up"} type={"primary"} style={{ width: isDesktop ? "176px" : "" }}>
              Sign Up
            </Button>
          </Link>
        }
      </div>
    </header>
  )
}

export default Header
