import React, { FC, ReactNode } from "react"
import "./index.less"

interface LandingContainerProps {
  children: ReactNode
}

const LandingContainer: FC<LandingContainerProps> = ({ children }) => {
  return (
    <div className={"land-container-back"}>
      <div className={"land-container"}>
        {children}
      </div>
    </div>
  )
}

export default LandingContainer
