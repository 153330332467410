import { FC, useLayoutEffect } from "react"
import { useLocation } from "react-router"
import { Outlet } from "react-router-dom"
import Logo from "src/shared/ui/Logo"

import "./index.less"

const AuthLayout: FC = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div style={{ minHeight: "100vh" }} className={"auth-wrap"}>
      <div className={"auth"}>
        <div className={"auth-container"}>
          <Logo showText={false} />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
