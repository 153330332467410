import { Link, useLocation } from "react-router-dom"
import { SvgIcon } from "../../../shared/svg"
import Logo from "../../../shared/ui/Logo"

import React, { useEffect, useState } from "react"
import { supportEmail } from "../../../mock/supportEmail"
import { ModalComponent } from "../../elements/modals/ModalComponent"
import "./Sidebar.less"

const Sidebar = () => {
  const [selectMenuItem, setSelectMenuItem] = useState<string | "">("")
  const { pathname } = useLocation()

  useEffect(() => {
    setSelectMenuItem(pathname.slice(1))
  }, [])

  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  return (
    <div className={"sidebar-container"}>
      <Logo showText={false} />
      <div className={"sidebar-main"}>
        <Link
          to={"/companies"}
          className={`sidebar-item ${selectMenuItem === "companies" ? "sidebar-item-selected" : ""}`}
          onClick={() => {
            setSelectMenuItem("companies")
          }}
        >
          <SvgIcon type={"companiesIcon"} style={{ marginRight: 8 }} />
          Companies
        </Link>
        <Link
          to={"/notifications"}
          className={`sidebar-item  ${selectMenuItem === "notifications" ? "sidebar-item-selected" : ""}`}
          onClick={() => {
            setSelectMenuItem("notifications")
          }}
        >
          <SvgIcon type={"bellIcon"} style={{ marginRight: 8 }} />
          Notifications
        </Link>
        <Link
          to={"/settings"}
          className={`sidebar-item ${selectMenuItem === "settings" ? "sidebar-item-selected" : ""}`}
          onClick={() => {
            setSelectMenuItem("settings")
          }}
        >
          <SvgIcon type={"settingIcons"} style={{ marginRight: 8 }} />
          Settings
        </Link>
        <Link to={"#"} onClick={showModal} className={"sidebar-item"}>
          <SvgIcon type={"questionIcon"} style={{ marginRight: 8 }} />
          Customer Service
        </Link>
      </div>
      <div className={"sidebar-footer"}>
        <Link to={"/extra#terms"} className={"sidebar-item"}>
          <SvgIcon type={"privacyIcon"} style={{ marginRight: 8 }} />
          Terms of Service
        </Link>
        <Link to={"/extra#privacy"} className={"sidebar-item"}>
          <SvgIcon type={"privacyIcon"} style={{ marginRight: 8 }} />
          Privacy Policy
        </Link>
      </div>
      <ModalComponent
        visible={isModalOpen}
        setVisibility={setIsModalOpen}
        title={"Do you need help?"}
        subtitle={
          <React.Fragment>
            Please contact us at{" "}
            <Link to={`mailto:${supportEmail}`}>
              <span>{supportEmail}</span>
            </Link>
            . We will get back to you as soon as possible and in no more than 2 business days.
          </React.Fragment>
        }
        icon={"question"}
        leftBtn={{ label: "Cancel", onClick: () => setIsModalOpen(false) }}
        rightBtn={{ label: "Write an email", onClick: () => window.location.replace(`mailto:${supportEmail}`) }}
      />
    </div>
  )
}
export default Sidebar
