import { NotificationInstance } from "antd/es/notification/interface"
import { instance } from "../../../hooks/axios"
import { notificationMessage } from "../../../themes/notification"

export type UseVerifyArgsType = {
  onSuccess?: () => void
  api: NotificationInstance
}

export type UseVerifyType = {
  verify: (id?: string | null) => Promise<void>
}
export function useVerify({ onSuccess, api }: UseVerifyArgsType): UseVerifyType {
  const verify: UseVerifyType["verify"] = async id => {
    if (id) {
      instance
        .post("/api/verify", { data: { filingId: id } })
        .then(() => onSuccess && onSuccess())
        .catch(err =>
          api.error(
            notificationMessage({
              message: err.response.data.error.message ?? err.message ?? "Something wrong",
              placement: "top",
              type: "error",
            })
          )
        )
      return
    }

    api.error(
      notificationMessage({
        message: "The company is not selected",
        placement: "top",
        type: "error",
      })
    )
  }

  return {
    verify,
  }
}
