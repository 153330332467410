import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "EmailDesignerEmailTemplate",
      "EmailEmitterEmail",
      "Filing",
      "FilingResult",
      "Home",
      "Layout",
      "LearnMore",
      "Notice",
      "Privacy",
      "ReactIconsIconlibrary",
      "Term",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "EmailDesignerEmailTemplate",
      "EmailEmitterEmail",
      "Filing",
      "FilingResult",
      "Home",
      "Layout",
      "LearnMore",
      "Notice",
      "Privacy",
      "ReactIconsIconlibrary",
      "Term",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const BankCardFragmentDoc = gql`
    fragment BankCard on BankCard {
  id
  last4
  expMonth
  expYear
  type
  connected
}
    `;
export const CheckoutSessionFragmentDoc = gql`
    fragment CheckoutSession on CheckoutSession {
  url
  isFirst
  defaultPaymentMethod
}
    `;
export const FilingResultFragmentDoc = gql`
    fragment FilingResult on FilingResult {
  year
  createdAt
  report {
    data {
      attributes {
        url
        name
        size
      }
    }
  }
}
    `;
export const FilingFragmentDoc = gql`
    fragment Filing on Filing {
  createdAt
  authorization
  company_file_number
  company_information
  company_name
  company_type
  directors
  fiscal_year
  inactivity
  issued_stock
  officers
  payment_method_id
  principal_place_of_business
  stock_information
  verify
  active
  phone
  email
  status
  company_data_confirmed
  error_type
  filing_results(sort: "year:desc") {
    data {
      id
      attributes {
        ...FilingResult
      }
    }
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const GridFragmentDoc = gql`
    fragment Grid on ComponentUiGrid {
  id
  visible
  children {
    ...Entry
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const NoticeFragmentDoc = gql`
    fragment Notice on Notice {
  createdAt
  description
  type
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
  title
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  visible
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const SubscriptionPlanFragmentDoc = gql`
    fragment SubscriptionPlan on SubscriptionPlan {
  date
  amount
  renewal
  subscriptionPrice
  additionalPrice
  maxFilingCount
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const AttachPaymentMethodDocument = gql`
    mutation attachPaymentMethod($paymentMethodId: String!) {
  attachPaymentMethod(paymentMethodId: $paymentMethodId) {
    result
  }
}
    `;
export type AttachPaymentMethodMutationFn = Apollo.MutationFunction<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>;
export function useAttachPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachPaymentMethodMutation, AttachPaymentMethodMutationVariables>(AttachPaymentMethodDocument, options);
      }
export type AttachPaymentMethodMutationHookResult = ReturnType<typeof useAttachPaymentMethodMutation>;
export type AttachPaymentMethodMutationResult = Apollo.MutationResult<AttachPaymentMethodMutation>;
export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $currentPassword: String!) {
  changePassword(
    password: $password
    currentPassword: $currentPassword
    passwordConfirmation: $password
  ) {
    jwt
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const ChargeAgainDocument = gql`
    mutation chargeAgain {
  chargeAgain {
    ...SubscriptionPlan
  }
}
    ${SubscriptionPlanFragmentDoc}`;
export type ChargeAgainMutationFn = Apollo.MutationFunction<ChargeAgainMutation, ChargeAgainMutationVariables>;
export function useChargeAgainMutation(baseOptions?: Apollo.MutationHookOptions<ChargeAgainMutation, ChargeAgainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChargeAgainMutation, ChargeAgainMutationVariables>(ChargeAgainDocument, options);
      }
export type ChargeAgainMutationHookResult = ReturnType<typeof useChargeAgainMutation>;
export type ChargeAgainMutationResult = Apollo.MutationResult<ChargeAgainMutation>;
export const CompanyChargeDocument = gql`
    mutation companyCharge($id: Int!) {
  companyCharge(id: $id) {
    id
    attributes {
      ...Filing
    }
  }
}
    ${FilingFragmentDoc}
${FilingResultFragmentDoc}`;
export type CompanyChargeMutationFn = Apollo.MutationFunction<CompanyChargeMutation, CompanyChargeMutationVariables>;
export function useCompanyChargeMutation(baseOptions?: Apollo.MutationHookOptions<CompanyChargeMutation, CompanyChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyChargeMutation, CompanyChargeMutationVariables>(CompanyChargeDocument, options);
      }
export type CompanyChargeMutationHookResult = ReturnType<typeof useCompanyChargeMutation>;
export type CompanyChargeMutationResult = Apollo.MutationResult<CompanyChargeMutation>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod {
  createPaymentMethod {
    ...CheckoutSession
  }
}
    ${CheckoutSessionFragmentDoc}`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($coupon: String, $isAdditional: Boolean) {
  createSubscription(coupon: $coupon, isAdditional: $isAdditional) {
    result
    message
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount {
    result
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export const DeleteCardDocument = gql`
    mutation deleteCard($id: ID!) {
  deleteCard(id: $id) {
    id
    deleted
  }
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export const DeleteFilingDocument = gql`
    mutation deleteFiling($id: ID!) {
  deleteFiling(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteFilingMutationFn = Apollo.MutationFunction<DeleteFilingMutation, DeleteFilingMutationVariables>;
export function useDeleteFilingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFilingMutation, DeleteFilingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFilingMutation, DeleteFilingMutationVariables>(DeleteFilingDocument, options);
      }
export type DeleteFilingMutationHookResult = ReturnType<typeof useDeleteFilingMutation>;
export type DeleteFilingMutationResult = Apollo.MutationResult<DeleteFilingMutation>;
export const DetachPaymentMethodDocument = gql`
    mutation detachPaymentMethod($paymentMethodId: String!) {
  detachPaymentMethod(paymentMethodId: $paymentMethodId) {
    result
  }
}
    `;
export type DetachPaymentMethodMutationFn = Apollo.MutationFunction<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>;
export function useDetachPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachPaymentMethodMutation, DetachPaymentMethodMutationVariables>(DetachPaymentMethodDocument, options);
      }
export type DetachPaymentMethodMutationHookResult = ReturnType<typeof useDetachPaymentMethodMutation>;
export type DetachPaymentMethodMutationResult = Apollo.MutationResult<DetachPaymentMethodMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
      deleted
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      email
      username
      role {
        id
        name
        type
      }
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const SendEmailDocument = gql`
    mutation sendEmail($type: String!, $email: String!) {
  sendEmail(type: $type, email: $email) {
    success
    message
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export const UpdateFilingDocument = gql`
    mutation updateFiling($id: ID!, $data: FilingInput!) {
  updateFiling(id: $id, data: $data) {
    data {
      id
      attributes {
        ...Filing
      }
    }
  }
}
    ${FilingFragmentDoc}
${FilingResultFragmentDoc}`;
export type UpdateFilingMutationFn = Apollo.MutationFunction<UpdateFilingMutation, UpdateFilingMutationVariables>;
export function useUpdateFilingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFilingMutation, UpdateFilingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFilingMutation, UpdateFilingMutationVariables>(UpdateFilingDocument, options);
      }
export type UpdateFilingMutationHookResult = ReturnType<typeof useUpdateFilingMutation>;
export type UpdateFilingMutationResult = Apollo.MutationResult<UpdateFilingMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
      attributes {
        id
        authorization
        phone
        firstVisit
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const VerifyDocument = gql`
    mutation verify($id: ID!) {
  verify(id: $id) {
    result
  }
}
    `;
export type VerifyMutationFn = Apollo.MutationFunction<VerifyMutation, VerifyMutationVariables>;
export function useVerifyMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMutation, VerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(VerifyDocument, options);
      }
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>;
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>;
export const CardsDocument = gql`
    query cards {
  cards {
    total
    data {
      ...BankCard
    }
  }
}
    ${BankCardFragmentDoc}`;
export function useCardsQuery(baseOptions?: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
      }
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options);
        }
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>;
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CheckCouponDocument = gql`
    query checkCoupon($coupon: String!) {
  checkCoupon(coupon: $coupon) {
    result
    couponCode
    amountOff
    percentOff
  }
}
    `;
export function useCheckCouponQuery(baseOptions: Apollo.QueryHookOptions<CheckCouponQuery, CheckCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCouponQuery, CheckCouponQueryVariables>(CheckCouponDocument, options);
      }
export function useCheckCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCouponQuery, CheckCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCouponQuery, CheckCouponQueryVariables>(CheckCouponDocument, options);
        }
export type CheckCouponQueryHookResult = ReturnType<typeof useCheckCouponQuery>;
export type CheckCouponLazyQueryHookResult = ReturnType<typeof useCheckCouponLazyQuery>;
export type CheckCouponQueryResult = Apollo.QueryResult<CheckCouponQuery, CheckCouponQueryVariables>;
export const CheckDeletedAccountDocument = gql`
    query checkDeletedAccount($email: String!) {
  checkDeletedAccount(email: $email) {
    result
  }
}
    `;
export function useCheckDeletedAccountQuery(baseOptions: Apollo.QueryHookOptions<CheckDeletedAccountQuery, CheckDeletedAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDeletedAccountQuery, CheckDeletedAccountQueryVariables>(CheckDeletedAccountDocument, options);
      }
export function useCheckDeletedAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDeletedAccountQuery, CheckDeletedAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDeletedAccountQuery, CheckDeletedAccountQueryVariables>(CheckDeletedAccountDocument, options);
        }
export type CheckDeletedAccountQueryHookResult = ReturnType<typeof useCheckDeletedAccountQuery>;
export type CheckDeletedAccountLazyQueryHookResult = ReturnType<typeof useCheckDeletedAccountLazyQuery>;
export type CheckDeletedAccountQueryResult = Apollo.QueryResult<CheckDeletedAccountQuery, CheckDeletedAccountQueryVariables>;
export const DefaultPaymentMethodDocument = gql`
    query defaultPaymentMethod {
  defaultPaymentMethod {
    paymentMethodId
  }
}
    `;
export function useDefaultPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>(DefaultPaymentMethodDocument, options);
      }
export function useDefaultPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>(DefaultPaymentMethodDocument, options);
        }
export type DefaultPaymentMethodQueryHookResult = ReturnType<typeof useDefaultPaymentMethodQuery>;
export type DefaultPaymentMethodLazyQueryHookResult = ReturnType<typeof useDefaultPaymentMethodLazyQuery>;
export type DefaultPaymentMethodQueryResult = Apollo.QueryResult<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>;
export const ExtraDocument = gql`
    query extra {
  privacy {
    data {
      id
      attributes {
        updatedAt
        title
        header {
          ...Paragraph
        }
        content(pagination: {limit: 20}) {
          ...Paragraph
        }
      }
    }
  }
  term {
    data {
      id
      attributes {
        updatedAt
        title
        header {
          ...Paragraph
        }
        content(pagination: {limit: 20}) {
          ...Paragraph
        }
      }
    }
  }
}
    ${ParagraphFragmentDoc}`;
export function useExtraQuery(baseOptions?: Apollo.QueryHookOptions<ExtraQuery, ExtraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExtraQuery, ExtraQueryVariables>(ExtraDocument, options);
      }
export function useExtraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExtraQuery, ExtraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExtraQuery, ExtraQueryVariables>(ExtraDocument, options);
        }
export type ExtraQueryHookResult = ReturnType<typeof useExtraQuery>;
export type ExtraLazyQueryHookResult = ReturnType<typeof useExtraLazyQuery>;
export type ExtraQueryResult = Apollo.QueryResult<ExtraQuery, ExtraQueryVariables>;
export const FilingDocument = gql`
    query filing($id: ID!) {
  filing(id: $id) {
    data {
      id
      attributes {
        ...Filing
      }
    }
  }
}
    ${FilingFragmentDoc}
${FilingResultFragmentDoc}`;
export function useFilingQuery(baseOptions: Apollo.QueryHookOptions<FilingQuery, FilingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilingQuery, FilingQueryVariables>(FilingDocument, options);
      }
export function useFilingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilingQuery, FilingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilingQuery, FilingQueryVariables>(FilingDocument, options);
        }
export type FilingQueryHookResult = ReturnType<typeof useFilingQuery>;
export type FilingLazyQueryHookResult = ReturnType<typeof useFilingLazyQuery>;
export type FilingQueryResult = Apollo.QueryResult<FilingQuery, FilingQueryVariables>;
export const FilingsDocument = gql`
    query filings($sort: [String], $filters: FilingFiltersInput) {
  filings(sort: $sort, filters: $filters) {
    data {
      id
      attributes {
        ...Filing
      }
    }
  }
}
    ${FilingFragmentDoc}
${FilingResultFragmentDoc}`;
export function useFilingsQuery(baseOptions?: Apollo.QueryHookOptions<FilingsQuery, FilingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilingsQuery, FilingsQueryVariables>(FilingsDocument, options);
      }
export function useFilingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilingsQuery, FilingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilingsQuery, FilingsQueryVariables>(FilingsDocument, options);
        }
export type FilingsQueryHookResult = ReturnType<typeof useFilingsQuery>;
export type FilingsLazyQueryHookResult = ReturnType<typeof useFilingsLazyQuery>;
export type FilingsQueryResult = Apollo.QueryResult<FilingsQuery, FilingsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($filter: UsersPermissionsUserFiltersInput) {
  usersPermissionsUsers(filters: $filter) {
    data {
      id
      attributes {
        email
        username
        createdAt
        company_id {
          data {
            attributes {
              createdAt
              company_name
              company_information
              company_file_number
              authorization
              company_type
              directors
              fiscal_year
              officers
              stock_information
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      id
      attributes {
        hero {
          ...Section
        }
        howItWorks {
          ...Headline
        }
        pricing {
          ...Headline
        }
        cta {
          ...Headline
        }
        ctaButton {
          ...Link
        }
        trialBanner {
          ...Tab
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}
${TabFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LearnMoreDocument = gql`
    query learnMore {
  learnMore {
    data {
      id
      attributes {
        hero {
          ...Card
        }
        description {
          ...Card
        }
        faq {
          ...Grid
        }
        overview {
          ...Tab
        }
      }
    }
  }
}
    ${CardFragmentDoc}
${FileFragmentDoc}
${GridFragmentDoc}
${EntryFragmentDoc}
${TabFragmentDoc}`;
export function useLearnMoreQuery(baseOptions?: Apollo.QueryHookOptions<LearnMoreQuery, LearnMoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LearnMoreQuery, LearnMoreQueryVariables>(LearnMoreDocument, options);
      }
export function useLearnMoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LearnMoreQuery, LearnMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LearnMoreQuery, LearnMoreQueryVariables>(LearnMoreDocument, options);
        }
export type LearnMoreQueryHookResult = ReturnType<typeof useLearnMoreQuery>;
export type LearnMoreLazyQueryHookResult = ReturnType<typeof useLearnMoreLazyQuery>;
export type LearnMoreQueryResult = Apollo.QueryResult<LearnMoreQuery, LearnMoreQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    role {
      data {
        id
      }
    }
    provider
    authorization
    phone
    trialActivationDate
    firstVisit
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NoticesDocument = gql`
    query notices($filters: NoticeFiltersInput) {
  notices(filters: $filters, sort: ["createdAt:desc"]) {
    data {
      id
      attributes {
        ...Notice
      }
    }
  }
}
    ${NoticeFragmentDoc}`;
export function useNoticesQuery(baseOptions?: Apollo.QueryHookOptions<NoticesQuery, NoticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoticesQuery, NoticesQueryVariables>(NoticesDocument, options);
      }
export function useNoticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoticesQuery, NoticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoticesQuery, NoticesQueryVariables>(NoticesDocument, options);
        }
export type NoticesQueryHookResult = ReturnType<typeof useNoticesQuery>;
export type NoticesLazyQueryHookResult = ReturnType<typeof useNoticesLazyQuery>;
export type NoticesQueryResult = Apollo.QueryResult<NoticesQuery, NoticesQueryVariables>;
export const SubscriptionPlanDocument = gql`
    query subscriptionPlan {
  subscriptionPlan {
    ...SubscriptionPlan
  }
}
    ${SubscriptionPlanFragmentDoc}`;
export function useSubscriptionPlanQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
      }
export function useSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
        }
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>;