import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { LogOutButton } from "../../elements/LogOutButton"
import { Row } from "antd"
import { useBreakpoints } from "../../../hooks/useBreakpoints"

export const LogOutLayout: FC = () => {
  const { isMobile } = useBreakpoints()
  return <Suspense>
    <Row justify={'end'} style={{position:'absolute', top:isMobile ? 80 : 30, right:isMobile ? 20 : 50}}>
      <LogOutButton/>
    </Row>
    <Outlet />
  </Suspense>
}

export default LogOutLayout