import { instance } from "./axios"

interface UseFilingMutationsResponse {
  createFiling: (values: FilingInput) => Promise<any>
  updateFiling: (id: string, values: FilingInput) => Promise<any>
}

export function useFilingMutations(): UseFilingMutationsResponse {
  const createFiling: UseFilingMutationsResponse["createFiling"] = async (values: FilingInput) => {
    return await instance.post("/api/filings", { data: values })
  }

  const updateFiling: UseFilingMutationsResponse["updateFiling"] = (id, values) => {
    return instance.put(`/api/filings/${id}`, { data: values })
  }

  return {
    createFiling,
    updateFiling,
  }
}
