import { Button } from "antd"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import Line from "../../../../../shared/ui/Line" // Создайте файл стилей для бургер меню
import "./BurgerMenu.less"
import { useAuth } from "../../../../app"
interface BurgerMenuProps {
  isOpen: boolean
  onClose: () => void
}
const BurgerMenu: FC<BurgerMenuProps> = ({ isOpen, onClose }) => {

  const {user} = useAuth()

  return (
    <div className={`burger-menu ${isOpen ? "open" : ""}`}>
      <div className={"burger-menu-container"}>
        <div className={"top-btns"}>
          <Link to={"/"} onClick={onClose}>
            <Button className={"log-in"}>Home Page</Button>
          </Link>
          <Link to={"/learn-more"} onClick={onClose}>
            <Button className={"log-in"}>Learn more</Button>
          </Link>
        </div>
        <Line margin={"48px 0 48px"} />
        <div className={"bottom-btns"}>
          {user
            ? <Link style={{ width: "100%" }} to={"/log-out"} onClick={onClose}>
              <Button style={{ width: "100%",  border: "1px solid rgba(255, 255, 255, 0.10)" }} block className={"log-in"}>
                Log out
              </Button>
            </Link>
            : <React.Fragment>
              <Link style={{ width: "100%" }} to={"/login"} onClick={onClose}>
                <Button type={"primary"} size={"large"} block>
                  Log In
                </Button>
              </Link>
              <Link style={{ width: "100%" }} to={"/sign-up"} onClick={onClose}>
                <Button style={{ width: "100%" }} block className={"log-in"}>
                Sign Up
                </Button>
              </Link>
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
